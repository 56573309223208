import axios from 'axios'
import { BASE_URL } from '../index'

export default {
  async createDapp(data, accessToken){
    let config = {
      headers: {
        Authorization: accessToken,
      }
    }

    try {
      return await axios.post( BASE_URL + '/manage/dapp', data, config)
      .then((response) => {
          return response
      })
      .catch((error) => {
          return error
      })
    } catch (error) {
        return error
    }
  },
  async getAllDappsofCurrentlyLoggedInUser(accessToken){
    let config = {
      headers: {
        Authorization: accessToken,
      }
    }

    try {
      return await axios.get( BASE_URL + '/manage/dapp/owner', config)
      .then((response) => {
          return response
      })
      .catch((error) => {
          return error
      })
    } catch (error) {
        return error
    }
  },
  async getDappbyID(id,accessToken){
    let config = {
      headers: {
        Authorization: accessToken,
      }
    }

    try {
      return await axios.get( BASE_URL + `/manage/dapp/${id}`, config)
      .then((response) => {
          return response
      })
      .catch((error) => {
          return error
      })
    } catch (error) {
        return error
    }
  },
  async createAPIKey(id,accessToken){
    let config = {
      method : "post",
      url : BASE_URL + `/manage/dapp/${id}/api-key`,
      headers: {
        Authorization: accessToken,
      }
    }

    try {
      return await axios(config)
      .then((response) => {
          return response
      })
      .catch((error) => {
          return error
      })
    } catch (error) {
        return error
    }
  },
  async deleteAPIKey(id, apiKeyName,accessToken){
    let config = {
      method : "delete",
      url : BASE_URL + `/manage/dapp/${id}/api-key/${apiKeyName}`,
      headers: {
        Authorization: accessToken,
      }
    }

    try {
      return await axios(config)
      .then((response) => {
          return response
      })
      .catch((error) => {
          return error
      })
    } catch (error) {
        return error
    }
  },


}