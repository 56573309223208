import React from 'react';
import {BrowserRouter, Routes, Route, Navigate} from "react-router-dom";
import './App.css';
import './styles.css'

//components
import Home from './components/Home';
import SignUp from './components/Auth/SignUp'
import Login from './components/Auth/Login'
import Dashboard from './components/Dashboard';
import ProjectInfo from './components/Dashboard/components/ProjectInfo';
//import Docs from './components/Docs';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route
            path="/"
            element={<Login/>}
          />
          <Route
            path="/signup"
            element={<SignUp/>}
          />
          <Route
            path="/login"
            element={<Login/>}
          />
          <Route
            path="/dashboard/*"
            element={
              <RequireAuth redirectTo="/login">
                <Dashboard/>
              </RequireAuth> 
            }
          />
          <Route
            path="/projects/*"
            element={
              <RequireAuth redirectTo="/login">
                <ProjectInfo/>
              </RequireAuth> 
            }
          />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

function RequireAuth({children, redirectTo}) {
  const token = JSON.parse(window.localStorage.getItem('accessData'));
  let isAuthenticated = token !== null && token !== undefined && token !== ""
  return isAuthenticated ? children : <Navigate to={redirectTo}/>;
}

export default App;