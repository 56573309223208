import { Fragment, useRef, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import CopyAlert from '../../../../../Alert/CopyAlert'
import {  ClipboardCopyIcon, ShieldExclamationIcon } from '@heroicons/react/outline'


export default function ShowResForCreatedApiKey({open, setOpen, newApiKey}) {
  const cancelButtonRef = useRef(null)

  const [showCopy, setShowCopy] = useState(false)

  function copyFunction(info) {
    navigator.clipboard.writeText(info)
    setShowCopy(true)
    setTimeout(() => setShowCopy(false), 1000)
  }
  
  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" className="flex items-center justify-center fixed z-10 inset-0 overflow-y-auto" initialFocus={cancelButtonRef} static onClose={() => null}>
        <div className="w-1/2 flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="relative inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full sm:p-6">
            <h3 className="block font-extrabold text-[24px] text-black bg-gradient-to-b from-[#58F2C9] via-[#48ECAF] to-[#2EE280] -mx-10 pl-10 py-5 -mt-6 ">New API Information</h3>
            <p className='text-black mt-3 text-lg font-medium flex py-2 bg-white'><ShieldExclamationIcon className='h-6 text-red-500 mr-2'/><u>Copy the Project ID and API key. This is your new API pairing!</u></p>

              <div className="flex-col sm:flex sm:items-start">
              <div className="block w-full">
                  <div className="mt-2">
                  {newApiKey ? Object?.keys(newApiKey).map(key =>
                    <div key={key}>
                      <div 
                      className='border-2 px-2 py-2 flex mt-4 rounded-lg hover:shadow-lg hover:bg-gray-50 cursor-pointer'
                      onClick={() => copyFunction(key)}
                      >                      
                        <p><b>Project ID:</b> {key}</p>
                        <div className='ml-auto mr-2 flex cursor-pointer'>
                          <span className='mr-2'>Copy </span> <ClipboardCopyIcon className='text-black h-6'/>
                        </div>
                      </div>
                      <div 
                      className='border-2 px-2 py-2 flex mt-4 rounded-lg hover:shadow-lg hover:bg-gray-50 cursor-pointer'
                      onClick={() => copyFunction(newApiKey[key])}
                      >                      
                        <p><b>API Key:</b> {newApiKey[key]}</p>
                        <div className='ml-auto mr-2 flex cursor-pointer'>
                          <span className='mr-2'>Copy </span> <ClipboardCopyIcon className='text-black h-6'/>
                        </div>
                      </div>
                    </div> 
                    ) :
                    null
                    }
                  </div>
                </div>
              </div>
              <div className="mt-5 sm:mt-4 sm:flex sm:flex-row-reverse">
                <button
                  type="button"
                  className="mt-3 w-full mt-6 inline-flex justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white text-base font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500  sm:w-auto sm:text-sm"
                  onClick={() => setOpen(false)}
                  ref={cancelButtonRef}
                >
                  Cancel
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
        {showCopy ?
        <CopyAlert />
        : <></> }
      </Dialog>
    </Transition.Root>
  )
}