import { Fragment, useState, useEffect, useCallback } from 'react'
import { NavLink } from 'react-router-dom';
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
  BellIcon,
  CalendarIcon,
  ChartBarIcon,
  FolderIcon,
  HomeIcon,
  InboxIcon,
  MenuAlt2Icon,
  UsersIcon,
  XIcon,
  LightningBoltIcon,
  documen
} from '@heroicons/react/outline'
import { SearchIcon } from '@heroicons/react/solid'

//components
import AddNewProject from './components/modals/AddNewProject'
import ShowResForAddedProject from './components/modals/ShowResForAddedProject'
import ProjectsTable from './components/ProjectsTable'
import logo from '../../assets/images/logo.png'

//api
import Dapps from '../../api/flexApi/dapps'

const navigation = [
  { name: 'Flex Projects', href: '#', icon: HomeIcon, current: true },
  { name: 'Flex Quickstart Guide', href: 'https://docs.flexonflow.com/flex-quick-start', icon: LightningBoltIcon, current: false },
  { name: 'API Documentation', href: 'https://docs.flexonflow.com/', icon: FolderIcon, current: false },
  // { name: 'Calendar', href: '#', icon: CalendarIcon, current: false },
  // { name: 'Documents', href: '#', icon: InboxIcon, current: false },
  // { name: 'Reports', href: '#', icon: ChartBarIcon, current: false },
]

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function Dashboard() {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const [openAddProjectModal, setOpenAddProjectModal] = useState(false)
  const [openAddProjectDataModal, setOpenAddProjectDataModal] = useState(false)
  const [projectName, setProjectName] = useState()
  const [projectDescription, setProjectDescription] = useState()
  const [projectData, setprojectData] = useState({})
  const [projects, setProjects] = useState([])

  const userNavigation = [
    { name: 'Your Profile', href: '#', onclick: null },
    { name: 'Settings', href: '#', onclick: null },
    { name: 'Sign out', href: '/', onclick: () => signOut()},
  ]

  const addProject = () =>{
    const data = {
        "name": projectName,
        "description": projectDescription
    }

    const accessData = JSON.parse(window.localStorage.getItem('accessData'));
    const token = 'Bearer ' + accessData.accessToken

    Dapps.createDapp(data, token)
    .then(response => {
        setprojectData(response.data)
        getAllProjects()
        setOpenAddProjectDataModal(true)
    })
    .catch(error => console.log(error))
    setOpenAddProjectModal(false)
  }

  const getAllProjects = useCallback(() => {
    const accessData = JSON.parse(window.localStorage.getItem('accessData'));
    const token = 'Bearer ' + accessData.accessToken

    Dapps.getAllDappsofCurrentlyLoggedInUser(token)
    .then(response => {
        setProjects(response.data)
    })
    .catch(error => console.log(error))
  },[]);

  const signOut = () => {
    localStorage.removeItem("accessData")
  }

  useEffect(() => {
    getAllProjects ()
  }, [getAllProjects]);

  return (
    <>
      <div className='font-sans'>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog as="div" className="relative z-40 md:hidden" onClose={setSidebarOpen}>
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 flex z-40">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-gray-800">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <button
                        type="button"
                        className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XIcon className="h-6 w-6 text-white" aria-hidden="true" />
                      </button>
                    </div>
                  </Transition.Child>
                  <div className="flex-shrink-0 flex items-center px-4">
                    <img
                      className="h-8 w-auto"
                      src="https://tailwindui.com/img/logos/workflow-logo-indigo-500-mark-white-text.svg"
                      alt="Workflow"
                    />
                  </div>
                  <div className="mt-5 flex-1 h-0 overflow-y-auto">
                    <nav className="px-2 space-y-1">
                      {navigation.map((item) => (
                        <a
                          key={item.name}
                          href={item.href}
                          className={classNames(
                            item.current
                              ? 'bg-gray-900 text-white'
                              : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                            'group flex items-center px-2 py-2 text-base font-medium rounded-md'
                          )}
                        >
                          <item.icon
                            className={classNames(
                              item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                              'mr-4 flex-shrink-0 h-6 w-6'
                            )}
                            aria-hidden="true"
                          />
                          {item.name}
                        </a>
                      ))}
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="flex-shrink-0 w-14" aria-hidden="true">
                {/* Dummy element to force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className="flex-1 flex flex-col min-h-0 bg-gray-800">
            <div className="flex items-center h-16 flex-shrink-0 px-4 py-16 bg-gray-900">
              <img
                className="h-44 w-auto mt-2 ml-3"
                src={logo}
                alt="Workflow"
              />
            </div>
            <div className="flex-1 flex flex-col overflow-y-auto">
              <nav className="flex-1 px-2 py-4 space-y-1">
                {navigation.map((item) => (
                  <a
                    key={item.name}
                    href={item.href}
                    className={classNames(
                      item.current ? 'bg-gray-900 text-white' : 'text-gray-300 hover:bg-gray-700 hover:text-white',
                      'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                    )}
                  >
                    <item.icon
                      className={classNames(
                        item.current ? 'text-gray-300' : 'text-gray-400 group-hover:text-gray-300',
                        'mr-3 flex-shrink-0 h-6 w-6'
                      )}
                      aria-hidden="true"
                    />
                    {item.name}
                  </a>
                ))}
              </nav>
            </div>
          </div>
        </div>
        <div className="md:pl-64 flex flex-col">
          <div className="sticky top-0 z-10 flex-shrink-0 flex h-16 bg-white shadow-lg">
            <button
              type="button"
              className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
            </button>
            <div className="flex-1 px-4 flex justify-between">
              <div className="ml-4 flex items-center md:ml-6">
                {/* Profile dropdown */}
                <Menu as="div" className="ml-3 relative">
                  <div>
                    
                  </div>
                  <Transition
                    as={Fragment}
                    enter="transition ease-out duration-100"
                    enterFrom="transform opacity-0 scale-95"
                    enterTo="transform opacity-100 scale-100"
                    leave="transition ease-in duration-75"
                    leaveFrom="transform opacity-100 scale-100"
                    leaveTo="transform opacity-0 scale-95"
                  >
                    <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                      {userNavigation.map((item) => (
                        <Menu.Item key={item.name}>
                          {({ active }) => (
                            <NavLink
                                to={item.href}
                                onClick={item.onclick}
                                className={classNames(
                                    active ? 'bg-gray-100' : '',
                                    'block px-4 py-2 text-sm text-gray-700'
                                )}
                            >
                                {item.name}
                            </NavLink>
                          )}
                        </Menu.Item>
                      ))}
                    </Menu.Items>
                  </Transition>
                </Menu>
              </div>
            </div>
          </div>

          <main className="flex-1">
            <div className="py-6">
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 flex">
                <h1 className="text-2xl font-semibold text-gray-900 ml-8 mt-1">Your Flex Projects</h1>
                <div className='ml-auto'>
                    <button 
                      className=' border-[1px] border-gray mr-8 rounded-md px-2 py-1 mt-1 bg-slate-700 text-slate-50 font-medium hover:bg-slate-400'
                      onClick={()=> setOpenAddProjectModal(true)}
                    >
                        + Add New Project
                    </button>
                </div>
              </div>
              <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8">
                {/* Replace with your content */}
                <div className="py-4">
                  <div>
                    <AddNewProject 
                      open={openAddProjectModal} 
                      setOpen={setOpenAddProjectModal}
                      setProjectName={setProjectName}
                      setProjectDescription={setProjectDescription}
                      addProject={addProject}
                    />            
                  </div>
                  <div>
                    <ShowResForAddedProject 
                      open={openAddProjectDataModal} 
                      setOpen={setOpenAddProjectDataModal}
                      projectData={projectData}
                    />
                  </div>
                  <div>
                    <ProjectsTable projects={projects}/>
                  </div> 
                </div>
                {/* /End replace */}
              </div>
            </div>
          </main>
        </div>
      </div>
    </>
  )
}