import axios from 'axios'
import { BASE_URL } from '../index'


export default {
  async register(data, deviceToken){
    let config = {
      headers: {
        Authorization: deviceToken,
      }
    }

    try {
      return await axios.post( BASE_URL + '/register', data, config)
      .then((response) => {
          return response
      })
      .catch((error) => {
          return error
      })
    } catch (error) {
        return error
    }
  },
  async authenticate(data, deviceToken){
    let config = {
      headers: {
        Authorization: deviceToken,
      }
    }

    try {
      return await axios.post( BASE_URL + '/auth/authenticate', data, config)
      .then((response) => {
          return response
      })
      .catch((error) => {
          return error
      })
    } catch (error) {
        return error
    }
  }
}