import { useState, useEffect, useCallback } from 'react'

//components
import Header from './components/Header'
import ProjectCard from './components/ProjectCard'
import ShowResForCreatedApiKey from './components/modals/ShowResForCreatedApiKey'
import PickKeyToDel from './components/modals/PickKeyToDel'
import Notification from './components/Notification'

//api
import Dapps from '../../../../api/flexApi/dapps'

export default function ProjectInfo() {
  const [project, setProject] = useState()
  //const [token, setToken] = useState()
  const [openShowDataModal, setOpenShowDataModal] = useState(false)
  const [openPickKeyModal, setOpenPickKeyModal] = useState(false)
  const [keyToDel, setKeyToDel] = useState()
  const [newApiKey, setNewApiKey] = useState()
  const [showNotification, setShowNotification] = useState(false)

  const getProjectInfo = useCallback(() => {
    const accessData = JSON.parse(window.localStorage.getItem('accessData'));
    const access = 'Bearer ' + accessData.accessToken
   
    const params = new Proxy(new URLSearchParams(window.location.search), {
        get: (searchParams, prop) => searchParams.get(prop),
    });
  
    Dapps.getDappbyID(params.projectid, access)
      .then(response => {
        setProject(response.data);
        setKeyToDel(Object?.keys(response.data.apiKey)[0])
      })
      .catch(err => {
          console.log(err);
      })
  },[]);

  useEffect(() => {
    getProjectInfo()
  }, [getProjectInfo])

  const createApiKey = () => {
    const accessData = JSON.parse(window.localStorage.getItem('accessData'));
    const access = 'Bearer ' + accessData.accessToken
   
    Dapps.createAPIKey(project.id, access)
      .then(response => {
        setNewApiKey(response.data);
        setOpenShowDataModal(true)
      })
      .catch(err => {
          console.log(err);
      })
      .finally(()=> {
        getProjectInfo()
      })
  }

  const deleteApiKey = () => {
    const accessData = JSON.parse(window.localStorage.getItem('accessData'));
    const access = 'Bearer ' + accessData.accessToken

    Dapps.deleteAPIKey(project.id, keyToDel, access)
      .then(() => {
        setOpenPickKeyModal(false)
        setShowNotification(true)
      })
      .catch(err => {
          console.log(err);
      })
      .finally(()=> {
        getProjectInfo()
      })
  }

  return (
    <div className="max-w-7xl mx-auto sm:px-6 lg:px-8">
      <div>
        <Header project={project}/>
      </div>
      <div>
        <Notification 
          show={showNotification}
          setShow={setShowNotification}
        />
      </div>
      <div className='mt-4'>
        <ProjectCard 
          project={project}
          createApiKey={createApiKey}
          setOpen={setOpenPickKeyModal}
        />
      </div>
      <div>
        <ShowResForCreatedApiKey 
          open={openShowDataModal} 
          setOpen={setOpenShowDataModal}
          newApiKey={newApiKey}
        />
      </div>
      <div>
        <PickKeyToDel
          open={openPickKeyModal} 
          setOpen={setOpenPickKeyModal}
          project={project}
          keyToDel={keyToDel}
          setKeyToDel={setKeyToDel}
          deleteApiKey={deleteApiKey}
        />
      </div>
    </div>
  )
}