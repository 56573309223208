import axios from 'axios'
import { BASE_URL } from '../index'

export default {
  async getDevice(data){
    try {
      return await axios.post( BASE_URL + '/device', data)
      .then((response) => {
          return response
      })
      .catch((error) => {
          return error
      })
  } catch (error) {
      return error
  }
  }
}