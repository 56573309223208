import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

//api
import Device from '../../../api/commonApi/device'
import Auth from '../../../api/commonApi/auth'

//components
import Alert from '../../Alert/Alert'

import logo from '../../../assets/images/logo.png'


export default function Login() {

  const navigate = useNavigate();
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [deviceToken, setDeviceToken] = useState('')
  const [showAlert, setShowAlert] = useState(false)
  
  const handleLogin = () => {
    const data = {
      "email": email,
      "password": password
    }

    Auth.authenticate(data, deviceToken)
    .then(response => {
      if (response.status === 200) {
        localStorage.setItem('accessData', JSON.stringify(response.data));
        navigate('/dashboard')
      } else {
        setShowAlert(response.response.data.message)
      }
    })
    .catch(error => console.log(error))
  }

  useEffect(() => {
    let isMounted = true;
    const getDevice = () => {
      const data = {
          "deviceUDID": "DUMMYKS",
          "pushToken": "",
          "timeZone": "EST",
          "appVersion": "1.0.2x",
          "osVersion": "14",
          "deviceType": "WEB",
          "userId": "",
          "deviceModel": "",
          "country": "",
          "language": ""
      }
      
      Device.getDevice(data)
        .then(response => {
          if(isMounted ){
            setDeviceToken('Bearer ' + response.data.accessToken)
          }
        })
        .catch(error => console.log(error));
    };

    getDevice();
    
    return () => {
      isMounted = false;
    };
  }, []);

  return(
    <div className='bg-black h-[100vh]'>
      {showAlert ? <Alert msg={showAlert}/> : null}
      <div className="min-h-full flex flex-col justify-center py-12 sm:px-6 lg:px-8">
        <div className="sm:mx-auto sm:w-full sm:max-w-md">
          <img
            className="mx-auto h-60 w-auto -mb-20 -mt-20"
            src={logo}
            alt="Workflow"
          />
          <h2 className="mt-6 text-center text-3xl font-extrabold text-white">Log in to your account</h2>
          {/* <p className="mt-2 text-center text-sm text-gray-600">
            Or{' '}
            <a href="#" className="font-medium text-indigo-600 hover:text-indigo-500">
              start your 14-day free trial
            </a>
          </p> */}
        </div>

        <div className="w-1/3 mt-8 sm:mx-auto border-2 shadow-lg border-[#A5FFEA] rounded-lg  ">
          <div className="bg-black py-8 px-4 shadow sm:rounded-lg sm:px-10">
            <div className="space-y-6">
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-white">
                  Email address
                </label>
                <div className="mt-1">
                  <input
                    id="email"
                    name="email"
                    type="email"
                    autoComplete="email"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </div>
              </div>

              <div>
                <label htmlFor="password" className="block text-sm font-medium text-white">
                  Password
                </label>
                <div className="mt-1">
                  <input
                    id="password"
                    name="password"
                    type="password"
                    autoComplete="current-password"
                    required
                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </div>
              </div>

              <div className="flex items-center justify-between">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
                  />
                  <label htmlFor="remember-me" className="ml-2 block text-sm text-white">
                    Remember me
                  </label>
                </div>

                <div className="text-sm">
                  <a href="#" className="font-medium text-white hover:text-indigo-500">
                    Forgot your password?
                  </a>
                </div>
              </div>

              <div>
                <button
                  type="submit"
                  className="relative bg-gradient-to-b sm:text-base lg:text-[24px] mt-5 from-[#58F2C9] via-[#48ECAF] to-[#2EE280] border-[2px] hover:text-white border-[#A5FFEA] block w-full sm:py-4 sm:px-4  text-xs py-2 rounded-md shadow text-black font-bold hover:from-teal-600 hover:to-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-cyan-400 focus:ring-offset-gray-900"
                  onClick={() => handleLogin()}
                >
                  Log in
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}